import React from "react";
import styled from "styled-components";

export default function ServiceBox({icon, title, subtitle}) {
  return (
    <Wrapper className="flex flexColumn">
      <IconStyle height={60} width={60} src={`./activities/${icon}`}/>
      <TitleStyle className="font18 semiBold">{title}</TitleStyle>
      {subtitle && <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
`;
const IconStyle = styled.img`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 10px 0;
  text-align: center;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
