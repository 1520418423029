import { Route, Routes as BaseRoutes } from "react-router-dom";
import Home from "./screens/Landing.jsx";
import ThankYou from './screens/ThankYou.jsx';

export default function Routes() {
  return (
    <BaseRoutes>
      <Route path="/" element={<Home />} />
      <Route path="thankyou" element={<ThankYou/>}/>
    </BaseRoutes>
  );
}
