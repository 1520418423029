import React from "react";
import styled from "styled-components";

export default function BlogBox({ tag, title, text, action, author }) {
  return (
    <WrapperBtn className="animate pointer">
      <Wrapper className="whiteBg radius8 shadow">
        {title && <h3 className="font30 extraBold" style={{ paddingTop: "30px" }}>{title}</h3>}
        <p className="font16" style={{ padding: "30px 0" }}>
          {text}
        </p>
      </Wrapper>
    </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 30px);
  text-align: left;
  padding: 0px 18px;
  margin-top: 30px;
  @media (max-width: 560px) {
    padding: 0 10px;
  }
`;
const WrapperBtn = styled.div`
  border: 0px;
  height: 100%;
  outline: none;
  cursor: default;
  background-color: transparent;
`;
