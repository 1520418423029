import React, { useEffect } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Project from '../components/Sections/Blog';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';

export default function Landing() {
  const location = useLocation();
  const search = location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  const section = params.get('section');

  useEffect(() => {
    // element which needs to be scrolled to
    setTimeout(() => {
      let element = document.getElementById(`${section}`);
      element?.scrollIntoView({behavior: 'smooth'});
    }, 500)
  }, []);
  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      {/*<Projects />*/}
      <Project />
      <Pricing />
      <Contact />
      <Footer />
      <ToastContainer />
    </>
  );
}


