import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="85" height="43" viewBox="0 0 436 218" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_702_2)">
        <path d="M78.8109 66.953C61.1 66.953 48.4414 55.2015 48.4414 35.2823C48.4414 15.3631 60.9334 3.83382 78.2835 3.83382C86.2506 3.83382 91.8304 5.83408 98.5206 10.3347L100.519 8.69555C93.4683 2.55588 86.2506 0 75.0356 0C52.7997 0 36.8932 13.0294 36.8932 35.2823C36.8932 57.5351 52.994 70.5645 74.869 70.5645C85.5289 70.5645 93.8569 68.3976 101.63 62.0635L99.631 60.4244C93.3017 64.7583 87.6942 66.9252 78.8387 66.9252H78.8109V66.953Z" fill="#6F8ABE"/>
        <path d="M244.4 66.5918H208.811V39.644H236.46V36.3935H208.811V4.00053H243.317V0.94458H198.485V69.6756H244.4V66.5918Z" fill="#6F8ABE"/>
        <path d="M313.3 66.5918H277.684V39.644H305.333V36.3935H277.684V4.00053H312.19V0.94458H267.357V69.6756H313.3V66.5918Z" fill="#6F8ABE"/>
        <path d="M295.923 169.716H260.306V142.768H287.983V139.518H260.306V107.125H294.673V104.041H250.007V172.8H295.923V169.716Z" fill="#6F8ABE"/>
        <path d="M390.668 0.94458L390.473 1.11127H390.668V0.94458Z" fill="#6F8ABE"/>
        <path d="M348.001 41.4498L356.662 33.1432L382.534 69.8701H394.638L363.907 26.2534L390.474 1.11133H386.31L348.001 36.9214V1.11133H337.702V69.8701H348.001V41.4498Z" fill="#6F8ABE"/>
        <path d="M64.5422 172.8H75.0355L94.7174 104.041H90.026L72.315 166.466L55.1315 104.041H44.6382L27.2882 166.466L10.8542 104.041H0L19.3488 172.8H29.8143L47.3587 111.097L64.5422 172.8Z" fill="#6F8ABE"/>
        <path d="M206.257 172.8V107.125H227.411V104.041H174.083V107.125H195.958C196.153 107.125 196.153 172.8 195.958 172.8H206.257Z" fill="#6F8ABE"/>
        <path d="M399.69 118.515C399.69 111.098 405.658 106.569 414.319 106.569C421.37 106.569 426.978 108.931 431.669 112.903L433.668 111.264C429.143 107.097 423.369 103.124 413.958 103.124C399.495 103.124 391.001 109.82 391.001 120.488C391.001 141.462 427.505 138.212 427.505 157.576C427.505 164.993 421.009 170.244 411.044 170.244C401.994 170.244 394.054 165.91 389.53 161.91L387.725 163.549C392.611 168.077 400.189 173.856 412.681 173.856C425.173 173.856 436 167.16 436 155.214C436 130.794 399.662 134.767 399.662 118.487L399.69 118.515Z" fill="#6F8ABE"/>
        <path d="M134.498 4.00047H143.186C156.012 4.00047 162.341 9.77899 162.341 20.2803C162.341 30.7817 156.012 35.4767 143.186 35.4767H141.021L166.699 69.8422H178.636C178.442 69.6477 154.402 38.0048 154.402 38.0048C166.338 36.0046 173.556 30.226 173.556 20.0859C173.556 6.88973 162.535 0.916748 144.463 0.916748H124.226V69.6755H134.525V4.00047H134.498Z" fill="#6F8ABE"/>
        <path d="M371.319 121.432C371.319 108.236 360.298 102.263 342.226 102.263H321.989V171.022H332.288V105.347H340.977C353.802 105.347 360.132 111.125 360.132 121.627C360.132 132.128 353.802 136.823 340.977 136.823L338.812 136.628L364.49 170.994H376.427L352.192 139.323C364.129 137.323 371.347 131.544 371.347 121.404L371.319 121.432Z" fill="#6F8ABE"/>
        <path d="M266.636 217.861C274.964 217.5 287.955 216.778 303.695 210.999C314.189 207.193 322.128 202.498 327.736 198.886C322.489 201.97 315.077 205.582 305.694 208.471C299.559 210.471 286.54 213.722 266.636 213.166C239.348 212.249 218 204.304 197.929 196.692C191.434 194.164 182.384 190.552 154.374 179.69C146.24 176.606 136.469 172.828 123.283 172.078C117.647 171.689 112.734 171.939 108.709 172.355C116.953 152.103 125.17 131.711 133.415 111.459C141.382 131.906 149.322 152.519 157.455 172.967H168.309L141.021 104.208H132.166L103.962 172.967L102.879 175.133C108.292 174.411 116.62 173.856 126.392 175.689C130.722 176.411 135.247 177.856 147.711 182.551C160.37 187.441 169.753 191.608 172.473 192.858C198.873 204.804 212.059 210.777 223.802 213.833C229.576 215.277 245.677 218.889 266.636 217.806V217.861Z" fill="#6F8ABE"/>
      </g>
      <defs>
        <clipPath id="clip0_702_2">
          <rect width="436" height="218" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}

export default SvgComponent;
