import React from "react";
import styled from "styled-components";

export default function PricingTable({ price, title, text,  offers }) {
  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {/*{getIcon}*/}
        <p className="font18 extraBold">{price}</p>
      </div>
      <div style={{ margin: "15px 0" }}>
        <h4 className="font20 semiBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                {/*<div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>*/}
                {/*  {item.cheked ? (*/}
                {/*    <div style={{ minWidth: "20px" }}>*/}
                {/*      <CheckMark />*/}
                {/*    </div>*/}
                {/*  ) : (*/}
                {/*    <div style={{ minWidth: "20px" }}></div>*/}
                {/*  )}*/}
                {/*</div>*/}
                <p className="font18 semiBold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 30px);
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
