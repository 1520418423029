import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import { Link } from 'react-scroll';
import AddImage1 from '../../assets/img/add/273x397.jpg';
import AddImage2 from '../../assets/img/add/174x293.jpg';
import AddImage3 from '../../assets/img/add/116x130.jpg';
import AddImage4 from '../../assets/img/add/174x198.jpg';

export default function Project() {
  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    }
  })

  function isBottom(el) {
    return el?.getBoundingClientRect().bottom - 200 <= window.innerHeight;
  }

  const trackScrolling = () => {
    const wrappedElement = document.getElementById('projectImagesContainer');
    if (isBottom(wrappedElement)) {
      console.log('header bottom reached');
      document.getElementById('projectImages').classList.add('animate')
      document.removeEventListener('scroll', trackScrolling);
    }
  };
  return (
    <Wrapper id="project">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter" id="projectImagesContainer">
            <AddLeft>
              {/*<h4 className="font15 semiBold">A few words about company</h4>*/}
              <h2 className="font40 extraBold">О проекте</h2>
              <p className="font14">
                Elvira — комплекс апартаментов от застройщика Emaar, расположившийся в сердце района Dubai Hills Estate. Рядом с комплексом разбит пышащий зеленью одноименный парк Dubai Hills Park с многочисленными игровыми и прогулочными зонами, тенистыми аллеями и площадками для барбекю и отдыха на открытом воздухе.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                <div style={{ width: "190px" }}>
                  <Link to="contact" smooth={true}><FullButton title="Оставить заявку" /></Link>
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <Link to="contact" smooth={true}>
                    <FullButton title="Обратная связь" border />
                  </Link>
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight id="projectImages">
              <AddRightInner>
                <div className="flexNullCenter">
                  <AddImgWrapp1 className="flexCenter">
                    <img src={AddImage1} alt="office" />
                  </AddImgWrapp1>
                  <AddImgWrapp2>
                    <img src={AddImage2} alt="office" />
                  </AddImgWrapp2>
                </div>
                <div className="flexNullCenter">
                  <AddImgWrapp3>
                    <img src={AddImage3} alt="office" />
                  </AddImgWrapp3>
                  <AddImgWrapp4>
                    <img src={AddImage4} alt="office" />
                  </AddImgWrapp4>
                </div>
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Почему Elvira?</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Удобства"
                text={
                  <span>
                    Эльвира находится в центре целостного сообщества, предлагая медицинские и образовательные учреждения мирового уровня, отличный отдых и велнес-услуги, а также первоклассный магазин и место для жизни, а любые удобства, которые вам могут понадобиться, находятся всего в двух шагах.
                    <ul>
                      <li>Баскетбольная площадка</li>
                      <li>Падель-теннис</li>
                      <li>Парк для собак</li>
                      <li>Волновой бассейн</li>
                      <li>Детские игровые площадки</li>
                      <li>Skate Park</li>
                      <li>Амфитеатр</li>
                      <li>Газоны для пикника</li>
                      <li>Беговая дорожка</li>
                      <li>Открытый тренажерный зал</li>
                    </ul>
                  </span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Местоположение"
                text={
                  <>
                    Отель Elvira находится всего в нескольких минутах от центра Дубая и других корпоративных, торговых и туристических центров благодаря удобному доступу к улице Аль-Хайл-роуд. В ближайшем будущем близость к предполагаемым линиям Etihad Rail и метро Дубая обеспечит быстрый и простой доступ к аэропортам и другим эмиратам.
                    <ul>
                      <li>12 минут до центра Дубая</li>
                      <li>15 минут до Дубай Марины</li>
                      <li>20 минут до международного аэропорта DXB</li>
                      <li>25 минут до международного аэропорта Аль-Мактум</li>
                    </ul>
                  </>
                }
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Завершение строительства"
                text={
                  <span>
                    Строительство жилого комплекса началось в IV квартале 2022 года. Планируемая дата передачи объекта в эксплуатацию – IV квартал 2026 года. Параллельно со строительством идет глобальная реконструкция района Mina Rashid. После завершения строительных работ он станет одним из самых престижных районов Дубая для отдыха, а также одним из крупнейших в городе центром яхтинга.
                    <br/><br/>Проект ЖК разрабатывает известный девелопер Emaar Properties с большим опытом работы и прекрасной репутацией на рынке недвижимости Дубая. Это говорит о том, что, покупая квартиру или таунхаус в строящемся ЖК Elvira по цене застройщика, вы можете быть уверены, что получите готовое жилье строго в обозначенные сроки.
                  </span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Недвижимость в Elvira для инвестиций"
                text={
                  <span>
                    Жилье в Elivra станет идеальным объектом для инвестирования. Пока ЖК строится, инвесторы могут приобрести квартиры и таунхаусы с хорошей скидкой от девелопера. После передачи ЖК в эксплуатацию капитальная стоимость квартир возрастет, и владельцы смогут выгодно их перепродать.
                    <br/><br/>Для долгосрочного инвестирования подойдет вариант сдачи недвижимости в аренду. Прибрежный район расположен рядом с пляжами, набережной и крупнейшим в городе круизным терминалом, и эта выигрышная локация будет привлекать арендаторов. Владельцы арендной недвижимости в Дубае обычно получают доход в размере 8-10% годовых.
                    <br/><br/>Информацию о доходности квартир в новом ЖК и все детали об инвестициях в недвижимость от застройщика Emaar Properties в Дубае можно узнать у наших специалистов.
                  </span>
                }
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-12 flexCenter flex x100" style={{marginTop: 30}}>
              <Link to="contact" smooth={true}><FullButton title="Оставить заявку" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  #projectImages {
    display: none;
  }
  #projectImages.animate {
    display: block;
    animation-duration: 0.5s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  }

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

